<template>
  <v-menu v-bind="$attrs" origin="top right" transition="scale-transition">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  name: "MenuMore",
};
</script>

<style></style>
